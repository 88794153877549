
.popup h1 { 
  font-size: 1.25rem;
  font-weight: bold;
  margin-block-start: 0.3rem;
  margin-block-end: 0.3rem;
}

.popup h2 { 
  font-size: 1.1rem;
  font-weight: bold;
  margin-block-start: 0.2rem;
  margin-block-end: 0.2rem;
}

.popup h3 { 
  font-size: 0.8rem;
  font-weight: bold;
  margin-block-start: 0.1rem;
  margin-block-end: 0.1rem;
}

.popup .result-table-row {
  display: flex;
  margin: 6px 2px; 
}

.popup .result-table-row .title{
  flex: 3 3 100px;
  font-size: larger;
  font-weight: 400;
}

.popup .result-table-row .value{
  flex: 1 1;
  font-weight: 600;
  font-size: larger;
}

.popup .result-table-row .secondary-text {
  font-size: smaller;
  font-weight: normal;
}

