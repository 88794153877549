
html, body, #root, .App, .main-page {
	height: 100%;
	width: 100%;
	position: relative;
}

body {
	font-size: 14px;
}

.main-page {
	display: flex;
	flex-direction: column;
	overflow:hidden;
}

@media (min-width: 1200px) {
	.main-page {
		width: 1200px;
		margin: 0 auto;
	}
}

button {
	cursor: pointer;
	vertical-align: middle;
	text-align: center;
	white-space: nowrap;
	box-sizing: border-box;
	display: inline-block;
	font-weight: 400;
	border: 1px solid transparent;
	padding: .375rem .75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: .25rem;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	margin: 0;
}

button:hover {
	text-decoration: none;
}

button:focus {
	outline: none;
}

button.btn-info {
	color: #17a2b8;
	border-color: #17a2b8;
	background-color: #fff;
}

button.btn-info:not(:disabled):not(.disabled):active {
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8;
}

button.btn-info:hover {
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8;
}

a.btn-info {
	color: #000;
	cursor: pointer;
}

a.btn-info:not(:disabled):not(.disabled):active {
	color: #000;
}

a.btn-info:hover {
	color: #17a2b8;

}

h1 {
	font-size: 2em;
}

nav {
	position: relative;
	background-color: transparent;
	display: flex;
	align-items: center;
	padding: .5rem 1rem;
	flex-flow: row nowrap;
	justify-content: flex-start;
}

a.navbar-brand {
	display: inline-block;
	padding-top: .3125rem;
	padding-bottom: .3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	line-height: inherit;
	white-space: nowrap;
	text-decoration: none;
	background-color: transparent;
	color: rgba(0,0,0,.9);
}

ul.navbar-nav {
	display: flex;
	flex-direction: row;
	padding-left: 0;
	margin-top: 0;
  margin-bottom: 0;
	list-style: none;
	margin-right: auto!important;
}

li.nav-item a.nav-link {
	color: rgba(0,0,0,.9);
	padding-right: 0;
	padding-left: 0;
	display: block;
	text-decoration: none;
	background-color: transparent;
	padding: .5rem 1rem;
}

li.nav-item a.nav-link.active {
	border-bottom: 1px solid darkgray;
}

@media (max-width: 500px) {
	h1 {
		font-size: 1em;
	}
}

@media (max-height: 500px) {
	h1 {
		font-size: 1em;
	}
}

.intro-text {
	font-size: 1.3em;
}

