
.school-pane .result-tile .result-entry{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.school-pane .result-tile .result-tile-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-weight: bolder;
}

.school-pane .close-button {
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  border: none;
  font-size: 16px;
  line-height: 14px;
  color: #c3c3c3;
  background: transparent;
  font-weight: bold;
  cursor: pointer;
}

.school-data-pane .school-contact-header {
  cursor: pointer;
  font-weight: bolder;
}

.school-data-pane .result-tile-header {
  cursor: pointer;
}

.school-data-pane .secondary-text {
  font-size: smaller;
  font-weight: normal;
}
