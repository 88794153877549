
.school-content {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  position: relative;
  align-items: stretch;
}

.map-container {
  height: 100%;
  width: 100%;
  max-height: 600px;
  min-height: 100px;
  flex: 1;
}

.map-container .map-placeholder {
  height: 100%;
  width: 100%;
  max-width: 1000px;
}

.school-data-pane {
  overflow: auto;
  height: 100%;
  width: auto;
  flex: 0 0 auto;
}

.school-data-pane  .inner-container {
  overflow: visible;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}

.school-pane {
  border: 1px solid #c3c3c3;
  border-radius: 4px;
  padding:  0.6rem;
  margin:  0.3rem;
  position: relative;
  max-width: 250px;
  min-width: 250px;
  flex: 1 0;
}

@media (orientation: portrait) {
  .school-content {
      flex-direction: column;
  }

  .school-data-pane  .inner-container {
      flex-direction: row;
  }

  .school-data-pane {
      height: auto;
      width: 100%;
  }
}


@media (max-height: 420px) and (max-width: 740px) {

  .school-data-pane {
      overflow: auto;
  }
}
