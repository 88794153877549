body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


html, body, #root, .App, .main-page {
	height: 100%;
	width: 100%;
	position: relative;
}

body {
	font-size: 14px;
}

.main-page {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	overflow:hidden;
}

@media (min-width: 1200px) {
	.main-page {
		width: 1200px;
		margin: 0 auto;
	}
}

button {
	cursor: pointer;
	vertical-align: middle;
	text-align: center;
	white-space: nowrap;
	box-sizing: border-box;
	display: inline-block;
	font-weight: 400;
	border: 1px solid transparent;
	padding: .375rem .75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: .25rem;
	-webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	margin: 0;
}

button:hover {
	text-decoration: none;
}

button:focus {
	outline: none;
}

button.btn-info {
	color: #17a2b8;
	border-color: #17a2b8;
	background-color: #fff;
}

button.btn-info:not(:disabled):not(.disabled):active {
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8;
}

button.btn-info:hover {
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8;
}

a.btn-info {
	color: #000;
	cursor: pointer;
}

a.btn-info:not(:disabled):not(.disabled):active {
	color: #000;
}

a.btn-info:hover {
	color: #17a2b8;

}

h1 {
	font-size: 2em;
}

nav {
	position: relative;
	background-color: transparent;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	padding: .5rem 1rem;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

a.navbar-brand {
	display: inline-block;
	padding-top: .3125rem;
	padding-bottom: .3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	line-height: inherit;
	white-space: nowrap;
	text-decoration: none;
	background-color: transparent;
	color: rgba(0,0,0,.9);
}

ul.navbar-nav {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	padding-left: 0;
	margin-top: 0;
  margin-bottom: 0;
	list-style: none;
	margin-right: auto!important;
}

li.nav-item a.nav-link {
	color: rgba(0,0,0,.9);
	padding-right: 0;
	padding-left: 0;
	display: block;
	text-decoration: none;
	background-color: transparent;
	padding: .5rem 1rem;
}

li.nav-item a.nav-link.active {
	border-bottom: 1px solid darkgray;
}

@media (max-width: 500px) {
	h1 {
		font-size: 1em;
	}
}

@media (max-height: 500px) {
	h1 {
		font-size: 1em;
	}
}

.intro-text {
	font-size: 1.3em;
}



.popup h1 { 
  font-size: 1.25rem;
  font-weight: bold;
  -webkit-margin-before: 0.3rem;
          margin-block-start: 0.3rem;
  -webkit-margin-after: 0.3rem;
          margin-block-end: 0.3rem;
}

.popup h2 { 
  font-size: 1.1rem;
  font-weight: bold;
  -webkit-margin-before: 0.2rem;
          margin-block-start: 0.2rem;
  -webkit-margin-after: 0.2rem;
          margin-block-end: 0.2rem;
}

.popup h3 { 
  font-size: 0.8rem;
  font-weight: bold;
  -webkit-margin-before: 0.1rem;
          margin-block-start: 0.1rem;
  -webkit-margin-after: 0.1rem;
          margin-block-end: 0.1rem;
}

.popup .result-table-row {
  display: -webkit-flex;
  display: flex;
  margin: 6px 2px; 
}

.popup .result-table-row .title{
  -webkit-flex: 3 3 100px;
          flex: 3 3 100px;
  font-size: larger;
  font-weight: 400;
}

.popup .result-table-row .value{
  -webkit-flex: 1 1;
          flex: 1 1;
  font-weight: 600;
  font-size: larger;
}

.popup .result-table-row .secondary-text {
  font-size: smaller;
  font-weight: normal;
}



.school-pane .result-tile .result-entry{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.school-pane .result-tile .result-tile-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-weight: bolder;
}

.school-pane .close-button {
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  border: none;
  font-size: 16px;
  line-height: 14px;
  color: #c3c3c3;
  background: transparent;
  font-weight: bold;
  cursor: pointer;
}

.school-data-pane .school-contact-header {
  cursor: pointer;
  font-weight: bolder;
}

.school-data-pane .result-tile-header {
  cursor: pointer;
}

.school-data-pane .secondary-text {
  font-size: smaller;
  font-weight: normal;
}

#elementary-page {
    min-height: 0px;
	width: 100%;
    position: relative;
    -webkit-flex: 1 1;
            flex: 1 1;
}


.school-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 100%;
  width: 100%;
  position: relative;
  -webkit-align-items: stretch;
          align-items: stretch;
}

.map-container {
  height: 100%;
  width: 100%;
  max-height: 600px;
  min-height: 100px;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.map-container .map-placeholder {
  height: 100%;
  width: 100%;
  max-width: 1000px;
}

.school-data-pane {
  overflow: auto;
  height: 100%;
  width: auto;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
}

.school-data-pane  .inner-container {
  overflow: visible;
  width: auto;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.school-pane {
  border: 1px solid #c3c3c3;
  border-radius: 4px;
  padding:  0.6rem;
  margin:  0.3rem;
  position: relative;
  max-width: 250px;
  min-width: 250px;
  -webkit-flex: 1 0;
          flex: 1 0;
}

@media (orientation: portrait) {
  .school-content {
      -webkit-flex-direction: column;
              flex-direction: column;
  }

  .school-data-pane  .inner-container {
      -webkit-flex-direction: row;
              flex-direction: row;
  }

  .school-data-pane {
      height: auto;
      width: 100%;
  }
}


@media (max-height: 420px) and (max-width: 740px) {

  .school-data-pane {
      overflow: auto;
  }
}

#upper-school-page {
  min-height: 0px;
  width: 100%;
  position: relative;
  -webkit-flex: 1 1;
          flex: 1 1;
}

